<template>
  <div class="service-wrapper">
    <div class="banner-wrapper">
      <h1 class="title">专业定制化的主播服务 直播卖货的贴心大管家</h1>
      <div class="desc">
        开通魔筷服务，选货、直播都省心，享受直播电商全链路服务
      </div>
      <div class="actions">
        <div class="btn btn-white" @click="jumpCollect">主播入驻</div>
        <div class="btn" @click="showVideo">观看视频</div>
      </div>
    </div>
    <div class="content-wrapper">
      <h2 class="title">6年沉淀，直播电商全链路服务体系</h2>
      <div class="content-desc">百万主播都在用的直播电商服务</div>
      <div class="select-group">
        <div class="title">
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252082850787799.png"
            alt=""
            class="icon"
          />
          <span class="text">选品组货</span>
        </div>
        <div class="desc">
          <div class="desc-item">
            <h3 class="title">基于“人工智能+大数据” 的人货匹配</h3>
            <div class="text">
              精准推荐：综合主播粉丝画像、过往直播情况等数据，精准匹配货源
            </div>
            <div class="text">
              爆款挖掘：通过对市场及商品销售数据的分析，助力主播定位爆款商品
            </div>
          </div>
          <div class="desc-item">
            <h3 class="title">深度了解货源，迅速提高带货效率</h3>
            <div class="text">
              营销专家级口播素材：素材多形式呈现，主播随学随卖
            </div>
            <div class="text">
              线上选品会： 与商品专家面对面，享大主播选品专属服务
            </div>
          </div>
        </div>
        <div class="main">
          <div class="img img-scene"></div>
          <div class="img img-data"></div>
        </div>
      </div>
      <div class="tab-section traffic-operation">
        <h2 class="title">
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252126301372595.png"
            alt=""
            class="icon"
          />
          <span class="text">流量运营</span>
        </h2>
        <div class="tabs">
          <h3
            class="tab-item"
            :class="operationIndex === 0 ? 'active' : ''"
            @mouseover="handleOperationCardChange(0)"
          >
            私域粉丝运营工具
          </h3>
          <h3
            class="tab-item"
            :class="operationIndex === 1 ? 'active' : ''"
            @mouseover="handleOperationCardChange(1)"
          >
            营销投流
          </h3>
        </div>
        <div class="panel" :class="currentOperationData.bgClass">
          <img
            ref="operationBanner"
            :src="currentOperationData.banner"
            alt=""
            class="banner"
          />
          <div ref="operationInfo" class="info">
            <h4 class="desc">
              <pre>{{ currentOperationData.desc }}</pre>
            </h4>
            <div class="services">
              <div
                v-for="(item, index) in currentOperationData.services"
                :key="index"
                class="service-item"
              >
                <img
                  src="https://mktv-in-cdn.mockuai.com/16252126301407665.png"
                  alt=""
                  class="icon"
                />
                <span class="text">{{ item }}</span>
              </div>
            </div>
            <a
              v-if="currentOperationData.more"
              :href="currentOperationData.more"
              target="_blank"
              class="more"
              >了解更多功能</a
            >
          </div>
        </div>
      </div>
      <div class="content-planning">
        <div class="info">
          <h2 class="title">
            <img
              data-src="https://mktv-in-cdn.mockuai.com/16252180739786517.png"
              alt=""
              class="icon"
            />
            <span class="text">内容策划</span>
          </h2>
          <div class="desc">
            <div class="desc-item">
              <h3 class="title">预热造势</h3>
              <div class="text">主播人设打造 | 预热视频制作｜宣传造势</div>
            </div>
            <div class="desc-item">
              <h3 class="title">直播内容导控</h3>
              <div class="text">内容创新 | 爆点设计｜节奏把控</div>
            </div>
            <div class="desc-item">
              <h3 class="title">整合营销活动</h3>
              <div class="text">工厂溯源｜大型营销｜日常促销</div>
            </div>
          </div>
        </div>
        <div class="main">
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252180739812828.png"
            alt=""
          />
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252180739877642.png"
            alt=""
          />
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252180739846326.png"
            alt=""
          />
        </div>
      </div>
      <div class="live-operation">
        <div class="info">
          <h2 class="title">
            <img
              data-src="https://mktv-in-cdn.mockuai.com/16252180739563587.png"
              alt=""
              class="icon"
            />
            <span class="text">直播运营</span>
          </h2>
          <div class="desc">
            <div class="desc-item">
              <h3 class="title">直播助播</h3>
              <div class="text">
                大型电商活动现场助播/驻场，协助主播更全面上架和介绍商品
              </div>
            </div>
            <div class="desc-item">
              <h3 class="title">气氛打造</h3>
              <div class="text">
                配置个性化直播氛围，调整带货节奏，稳定直播间流量
              </div>
            </div>
            <div class="desc-item">
              <h3 class="title">活动复盘</h3>
              <div class="text">通过直播数据分析，输出带货策略</div>
            </div>
          </div>
        </div>
        <div class="main">
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252180739748159.png"
            alt=""
          />
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252180739703482.png"
            alt=""
          />
        </div>
      </div>
      <div class="tab-section aftersale-management">
        <h2 class="title">
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16252235252801378.png"
            alt=""
            class="icon"
          />
          <span class="text">售后管理</span>
        </h2>
        <div class="tabs">
          <h3
            class="tab-item"
            :class="aftersaleIndex === 0 ? 'active' : ''"
            @mouseover="handleAftersaleChange(0)"
          >
            仓储物流
          </h3>
          <h3
            class="tab-item"
            :class="aftersaleIndex === 1 ? 'active' : ''"
            @mouseover="handleAftersaleChange(1)"
          >
            客户服务
          </h3>
        </div>
        <div class="panel" :class="currentAftersaleData.bgClass">
          <img
            ref="aftersaleBanner"
            v-if="currentAftersaleData.banner"
            :src="currentAftersaleData.banner"
            alt=""
            class="banner"
          />
          <div ref="aftersaleInfo" class="info">
            <div class="desc">{{ currentAftersaleData.desc }}</div>
            <div class="points">
              <div
                v-for="(item, index) in currentAftersaleData.points"
                :key="index"
                class="point-item"
              >
                {{ item }}
              </div>
            </div>
            <a
              v-if="currentAftersaleData.more"
              :href="currentAftersaleData.more"
              target="_blank"
              class="more"
              >详询了解更多</a
            >
          </div>
        </div>
      </div>
    </div>
    <video-modal
      :visible="videoModalVisible"
      :src="videoSrc"
      @close="closeVideo"
    ></video-modal>
  </div>
</template>

<script>
import VideoModal from "@/components/VideoModal.vue";
export default {
  components: {
    VideoModal,
  },
  data() {
    return {
      videoModalVisible: false,
      videoSrc: "",
      operationIndex: 0,
      operationTabData: [
        {
          bgClass: "panel-scrm",
          banner: "https://mktv-in-cdn.mockuai.com/16275403484003320.png",
          desc: `基于人工智能的一站式自动化粉丝运营解决方案\n让直播卖货事半功倍`,
          more: "/scrm",
          services: [
            "建联加粉",
            "客户管理",
            "开播提醒",
            "会员售后",
            "提高复购",
            "选品中台",
            "红包返现",
            "活码工具",
          ],
        },
        {
          bgClass: "panel-marketing",
          banner: "https://mktv-in-cdn.mockuai.com/16252126301245213.png",
          desc: `通过人群定位、内容优化等方式对营销投放策略进行\n优化及调整，为主播高效引入电商粉丝！`,
          services: ["账户分析", "投流策划", "流量管控", "成本管理"],
        },
      ],
      aftersaleIndex: 0,
      aftersaleTabData: [
        {
          bgClass: "panel-storage",
          banner: "https://mktv-in-cdn.mockuai.com/16252235252835021.png",
          desc: "魔筷云仓-高适配直播电商的仓储物流能力",
          more: "/collect-cloudstore",
          points: [
            "速度快：9小时发货率90%，24小时发货率99%",
            "有保障：专业团队，满意率>90%",
            "分布广：全国建百个云仓",
          ],
        },
        {
          bgClass: "panel-customer",
          desc: "贴心完善的售后体系，让主播更专注卖货",
          points: [
            "专业团队：500+专业客服",
            "迅捷沟通：在线客服与400热线客服",
            "服务质量：好评率>95%",
            "快速审核：7天24小时随时待命",
          ],
        },
      ],
    };
  },
  computed: {
    currentOperationData() {
      return this.operationTabData[this.operationIndex];
    },
    currentAftersaleData() {
      return this.aftersaleTabData[this.aftersaleIndex];
    },
  },
  mounted() {},
  methods: {
    handleOperationCardChange(index) {
      if (this.operationIndex === index) {
        return;
      }
      this.operationIndex = index;

      this.$nextTick(() => {
        // this.$refs.operationBanner.classList.remove('fade-in')
        this.$refs.operationInfo.classList.remove("fade-in-creative");
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            // this.$refs.operationBanner.classList.add('fade-in')
            this.$refs.operationInfo.classList.add("fade-in-creative");
          });
        });
      });
    },
    handleAftersaleChange(index) {
      if (this.aftersaleIndex === index) {
        return;
      }
      this.aftersaleIndex = index;

      this.$nextTick(() => {
        // this.$refs.aftersaleBanner && this.$refs.aftersaleBanner.classList.remove('fade-in')
        this.$refs.aftersaleInfo.classList.remove("fade-in-creative");
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            // this.$refs.aftersaleBanner && this.$refs.aftersaleBanner.classList.add('fade-in')
            this.$refs.aftersaleInfo.classList.add("fade-in-creative");
          });
        });
      });
    },
    showVideo() {
      console.log("点击了");
      this.videoSrc = "https://mktv-in-cdn.mockuai.com/16255555171401345.mp4";
      this.videoModalVisible = true;
    },
    closeVideo() {
      console.log("点击了");

      this.videoModalVisible = false;
    },
    jumpCollect() {
      // window.location.href = '/collect-anchor'
      window.open("/collect-anchor");
      // this.$router.push('/collect-anchor')
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/common.less";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-creative {
  0% {
    opacity: 0;
    transform: translate3d(0, 90px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.fade-in-creative {
  animation: fade-in-creative 1s ease 0s normal both;
}
.fade-in {
  animation: fade-in 1s ease 0s 1 normal both;
}

.service-wrapper {
  .banner-wrapper {
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .pxToVw(height, 680);
    background-color: #00092a;
    background-image: url(https://mktv-in-cdn.mockuai.com/16251232440022854.png);
    background-size: cover;
    background-repeat: no-repeat;
    h1.title {
      .pxToVw(margin-bottom, 13);
      line-height: 1.4;
      .pxToVw(font-size, 60);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff;
    }
    .desc {
      .pxToVw(line-height, 33);
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff;
    }
    .actions {
      display: flex;
      align-items: center;
      .pxToVw(margin-top, 77);
      .btn {
        box-sizing: border-box;
        .pxToVw(width, 220);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        text-align: center;
        .pxToVw(margin-right, 40);
        border: 1px solid #fff;
        .pxToVw(border-radius, 30);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          border: none;
          background-color: #fff;
          color: #333;
        }
        &.btn-white {
          background-color: #fff;
          color: #ff6600;
          &:hover {
            background-color: #ff6600;
            color: #fff;
          }
        }
      }
    }
  }
  .content-wrapper {
    .pxToVw(padding-top, 92);
    background-color: #f8f8f8;
    h2.title {
      margin: 0 auto 8 * @unitVw;
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .content-desc {
      .pxToVw(margin-bottom, 182);
      text-align: center;
      .pxToVw(line-height, 25);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .select-group {
      position: relative;
      box-sizing: border-box;
      .pxToVw(padding-top, 118);
      .pxToVw(padding-left, 240);
      .pxToVw(padding-bottom, 140);
      border-top-left-radius: 40 * @unitVw;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      > .title {
        display: flex;
        align-items: center;
        .icon {
          .pxToVw(width, 54);
          .pxToVw(height, 46);
          .pxToVw(margin-right, 10);
        }
        .text {
          .pxToVw(font-size, 36);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff6600;
        }
      }
      > .desc {
        .pxToVw(margin-top, 56);
        .desc-item {
          .pxToVw(margin-bottom, 50);
          .title {
            .pxToVw(margin-bottom, 8);
            .pxToVw(line-height, 33);
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .text {
            .pxToVw(line-height, 30);
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #666666;
          }
        }
      }
      .main {
        position: absolute;
        .pxToVw(top, -78);
        .pxToVw(right, 240);
        .pxToVw(width, 780);
        .pxToVw(height, 600);
        .pxToVw(border-radius, 16);
        background-color: #001554;
        background-image: url(https://mktv-in-cdn.mockuai.com/16256410046946780.png);
        background-size: cover;
        background-repeat: no-repeat;
        > .img {
          background-size: cover;
          background-repeat: no-repeat;
        }
        .img-scene {
          position: absolute;
          .pxToVw(top, 39);
          .pxToVw(left, -116);
          .pxToVw(width, 289);
          .pxToVw(height, 289);
          background-color: #001554;
          background-image: url(https://mktv-in-cdn.mockuai.com/16252082851189123.png);
          border-radius: 50%;
          overflow: hidden;
        }
        .img-data {
          position: absolute;
          .pxToVw(top, 101);
          .pxToVw(left, 74);
          .pxToVw(width, 652);
          .pxToVw(height, 414);
          background-image: url(https://mktv-in-cdn.mockuai.com/16252082851234078.png);
        }
      }
    }
    .tab-section {
      .pxToVw(padding-top, 80);
      .pxToVw(padding-bottom, 104);
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 62 * @unitVw;
        .icon {
          .pxToVw(width, 52);
          .pxToVw(height, 48);
          .pxToVw(margin-right, 9);
        }
        .text {
          .pxToVw(line-height, 40);
          .pxToVw(font-size, 36);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff6600;
        }
      }
      .tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pxToVw(margin-left, 518);
        .pxToVw(margin-right, 579);
        .tab-item {
          position: relative;
          .pxToVw(padding-bottom, 19);
          line-height: 1.4;
          .pxToVw(font-size, 30);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            .pxToVw(width, 72);
            .pxToVw(height, 6);
            .pxToVw(border-radius, 3);
            background-color: #ddd;
          }
          &.active {
            &::after {
              background-color: #ff6600;
            }
          }
        }
      }
      &.traffic-operation {
        .panel {
          position: relative;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .pxToVw(width, 1440);
          .pxToVw(height, 600);
          margin: 46 * @unitVw auto 0;
          .pxToVw(border-radius, 16);
          background-color: #001554;
          background-size: cover;
          background-repeat: no-repeat;
          &.panel-scrm {
            background-image: url(https://mktv-in-cdn.mockuai.com/16252126301467595.png);
            .banner {
              .pxToVw(top, 69);
              .pxToVw(left, 81);
              .pxToVw(width, 485);
              .pxToVw(height, 485);
            }
          }
          &.panel-marketing {
            background-image: url(https://mktv-in-cdn.mockuai.com/16252126301346535.png);
            .banner {
              .pxToVw(top, 18);
              .pxToVw(left, 121);
              .pxToVw(width, 411);
              .pxToVw(height, 582);
            }
            .info {
              .desc {
                .pxToVw(margin-bottom, 100);
              }
            }
          }
          .banner {
            position: absolute;
          }
          .info {
            .pxToVw(margin-left, 720);
            .pxToVw(width, 550);
            .desc {
              margin: 0;
              .pxToVw(margin-bottom, 47);
              .pxToVw(line-height, 40);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #fff;
            }
            .services {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              .service-item {
                display: flex;
                align-items: center;
                .pxToVw(margin-right, 40);
                .pxToVw(margin-bottom, 26);
                &:nth-child(4n) {
                  margin-right: 0;
                }
                .icon {
                  .pxToVw(width, 20);
                  .pxToVw(height, 15);
                  .pxToVw(margin-right, 10);
                }
                .text {
                  .pxToVw(line-height, 25);
                  .pxToVw(font-size, 18);
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 300;
                  color: #fff;
                }
              }
            }
            .more {
              display: block;
              text-decoration: none;
              .pxToVw(margin-top, 33);
              .pxToVw(width, 220);
              .pxToVw(height, 60);
              .pxToVw(line-height, 60);
              text-align: center;
              .pxToVw(border-radius, 30);
              background-color: #fff;
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 400;
              color: #ff6600;
              cursor: pointer;
              &:hover {
                font-weight: 600;
              }
            }
          }
        }
      }
      &.aftersale-management {
        .panel {
          position: relative;
          box-sizing: border-box;
          .pxToVw(width, 1440);
          .pxToVw(height, 600);
          margin: 48 * @unitVw auto 0;
          .pxToVw(padding-top, 150);
          .pxToVw(padding-left, 91);
          .pxToVw(border-radius, 16);
          background-color: #001554;
          background-size: cover;
          background-repeat: no-repeat;
          &.panel-storage {
            background-image: url(https://mktv-in-cdn.mockuai.com/16309803039358500.png);
            .banner {
              .pxToVw(top, 69);
              .pxToVw(right, 79);
              .pxToVw(width, 575);
              .pxToVw(height, 484);
            }
          }
          &.panel-customer {
            .pxToVw(padding-top, 174);
            background-image: url(https://mktv-in-cdn.mockuai.com/16252235252714282.png);
          }
          .info {
            .desc {
              .pxToVw(margin-bottom, 20);
              .pxToVw(line-height, 33);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
            }
            .points {
              .point-item {
                .pxToVw(line-height, 50);
                .pxToVw(font-size, 18);
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #ffffff;
              }
            }
            .more {
              display: block;
              text-decoration: none;
              .pxToVw(margin-top, 33);
              .pxToVw(width, 220);
              .pxToVw(height, 60);
              .pxToVw(line-height, 60);
              text-align: center;
              .pxToVw(border-radius, 30);
              background-color: #fff;
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 400;
              color: #ff6600;
              cursor: pointer;
              &:hover {
                font-weight: 600;
              }
            }
          }
          .banner {
            position: absolute;
          }
        }
      }
    }
    .content-planning {
      position: relative;
      box-sizing: border-box;
      .pxToVw(margin-left, 120);
      .pxToVw(margin-bottom, 202);
      .pxToVw(width, 1800);
      .pxToVw(height, 680);
      .pxToVw(padding-top, 82);
      .pxToVw(padding-bottom, 92);
      border-top-left-radius: 40 * @unitVw;
      border-bottom-left-radius: 40 * @unitVw;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      .info {
        .pxToVw(margin-left, 120);
        .pxToVw(width, 330);
        > .title {
          display: flex;
          align-items: center;
          .pxToVw(margin-bottom, 141);
          .icon {
            .pxToVw(width, 48);
            .pxToVw(height, 52);
            .pxToVw(margin-right, 7);
          }
          .text {
            .pxToVw(font-size, 36);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ff6600;
          }
        }
        .desc {
          .desc-item {
            .pxToVw(margin-bottom, 48);
            .title {
              .pxToVw(margin-bottom, 8);
              .pxToVw(line-height, 33);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .text {
              .pxToVw(line-height, 30);
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
            }
          }
        }
      }
      .main {
        position: absolute;
        .pxToVw(left, 569);
        .pxToVw(top, 82);
        display: flex;
        align-items: center;
        img {
          .pxToVw(margin-right, 19);
          background-color: #001554;
          .pxToVw(border-radius, 16);
          overflow: hidden;
          &:nth-child(1) {
            .pxToVw(width, 270);
          }
          &:nth-child(2) {
            .pxToVw(width, 341);
          }
          &:nth-child(3) {
            .pxToVw(width, 340);
          }
        }
      }
    }
    .live-operation {
      position: relative;
      box-sizing: border-box;
      .pxToVw(width, 1680);
      .pxToVw(height, 680);
      .pxToVw(padding-top, 80);
      .pxToVw(padding-bottom, 123);
      .pxToVw(margin-bottom, 160);
      border-top-right-radius: 40 * @unitVw;
      border-bottom-right-radius: 40 * @unitVw;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      .info {
        .pxToVw(margin-left, 1020);
        > .title {
          display: flex;
          align-items: center;
          .pxToVw(margin-bottom, 116);
          .icon {
            .pxToVw(width, 50);
            .pxToVw(height, 48);
            .pxToVw(margin-right, 19);
          }
          .text {
            .pxToVw(line-height, 40);
            .pxToVw(font-size, 36);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ff6600;
          }
        }
        .desc {
          .desc-item {
            .pxToVw(margin-bottom, 48);
            .title {
              .pxToVw(margin-bottom, 8);
              .pxToVw(line-height, 33);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .text {
              .pxToVw(line-height, 30);
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666;
            }
          }
        }
      }
      .main {
        position: absolute;
        .pxToVw(left, 240);
        .pxToVw(top, 110);
        display: flex;
        align-items: center;
        img {
          .pxToVw(margin-right, 16);
          background-color: #001554;
          .pxToVw(border-radius, 16);
          overflow: hidden;
          &:nth-child(1) {
            .pxToVw(width, 351);
          }
          &:nth-child(2) {
            .pxToVw(width, 310);
          }
        }
      }
    }
  }
}

@media screen and(max-width: 1066px) {
  .service-wrapper {
    .banner-wrapper {
      height: 377.531px;
      h1.title {
        width: 677.3px;
        margin-bottom: 7.21771px;
        font-size: 33.3125px;
      }
      .desc {
        line-height: 18.3219px;
        font-size: 13.325px;
      }
      .actions {
        .btn {
          margin-right: 22.2083px;
          width: 122.141px;
          height: 33.3125px;
          line-height: 33.3125px;
          border-radius: 16.6562px;
          font-size: 13.325px;
        }
      }
    }
    .content-wrapper {
      padding-top: 51.079px;
      h2.title {
        margin-bottom: 4.442px;
        line-height: 27.7604px;
        font-size: 19.9875px;
      }
      .content-desc {
        margin-bottom: 101.048px;
        line-height: 13.8802px;
        font-size: 10px;
      }
      .select-group {
        padding-top: 65.5146px;
        padding-left: 133.25px;
        padding-bottom: 77.7292px;
        border-top-left-radius: 22.2083px;
        > .title {
          .icon {
            width: 29.969px;
            height: 25.531px;
            margin-right: 5.55208px;
          }
          .text {
            font-size: 19.9875px;
          }
        }
        > .desc {
          margin-top: 31.0917px;
          .desc-item {
            margin-bottom: 27.7604px;
            .title {
              margin-bottom: 4.44167px;
              line-height: 18.3219px;
              font-size: 13.325px;
            }
            .text {
              line-height: 16.6562px;
              font-size: 10px;
            }
          }
        }
        .main {
          top: -43.3063px;
          right: 133.25px;
          width: 433.062px;
          height: 333.125px;
          border-radius: 8.88333px;
          .img-scene {
            top: 21.6531px;
            left: -64.4042px;
            width: 160.453px;
            height: 160.453px;
          }
          .img-data {
            top: 56.076px;
            left: 41.0854px;
            width: 361.984px;
            height: 229.844px;
          }
        }
      }
      .tab-section {
        padding-top: 44.4167px;
        padding-bottom: 57.7417px;
        .title {
          margin-bottom: 34.4229px;
          .icon {
            width: 28.8594px;
            height: 26.6406px;
            margin-right: 4.99687px;
          }
          .text {
            line-height: 22.2083px;
            font-size: 19.9875px;
          }
        }
        .tabs {
          margin-left: 287.598px;
          margin-right: 321.466px;
          .tab-item {
            padding-bottom: 10.549px;
            font-size: 16.6562px;
            &::after {
              width: 39.9688px;
              height: 3.32812px;
              border-radius: 1.66562px;
            }
          }
        }
        &.traffic-operation {
          .panel {
            width: 799.5px;
            height: 333.125px;
            margin-top: 25.5396px;
            border-radius: 8.88333px;
            &.panel-scrm {
              .banner {
                top: 38.3094px;
                left: 44.9719px;
                width: 269.266px;
                height: 269.266px;
              }
            }
            &.panel-marketing {
              .banner {
                top: 9.99375px;
                left: 67.1802px;
                width: 228.188px;
                height: 323.125px;
              }
              .info {
                .desc {
                  margin-bottom: 55.5208px;
                }
              }
            }
            .info {
              margin-left: 399.75px;
              width: 305.359px;
              .desc {
                margin-bottom: 26.0948px;
                line-height: 22.2083px;
                font-size: 13.325px;
              }
              .services {
                .service-item {
                  margin-right: 22.2083px;
                  margin-bottom: 14.4354px;
                  .icon {
                    width: 11.0938px;
                    height: 8.32812px;
                    margin-right: 5.55208px;
                  }
                  .text {
                    line-height: 13.8802px;
                    font-size: 10px;
                  }
                }
              }
              .more {
                margin-top: 18.3219px;
                width: 122.141px;
                height: 33.3125px;
                line-height: 33.3125px;
                border-radius: 16.6562px;
                font-size: 13.325px;
              }
            }
          }
        }
        &.aftersale-management {
          .panel {
            width: 799.5px;
            height: 333.125px;
            margin-top: 26.65px;
            padding-top: 83.2812px;
            padding-left: 50.524px;
            border-radius: 8.88333px;
            &.panel-storage {
              .banner {
                top: 38.3094px;
                right: 43.8615px;
                width: 319.234px;
                height: 268.719px;
              }
            }
            &.panel-customer {
              padding-top: 96.6062px;
            }
            .info {
              .desc {
                margin-bottom: 11.1042px;
                line-height: 18.3219px;
                font-size: 13.325px;
              }
              .points {
                .point-item {
                  line-height: 27.7604px;
                  font-size: 10px;
                }
              }
              .more {
                margin-top: 18.3219px;
                width: 122.141px;
                height: 33.3125px;
                line-height: 33.3125px;
                border-radius: 16.6562px;
                font-size: 13.325px;
              }
            }
          }
        }
      }
      .content-planning {
        margin-left: 66.625px;
        margin-bottom: 112.152px;
        width: 999.375px;
        height: 377.531px;
        padding-top: 45.5271px;
        padding-bottom: 51.0792px;
        border-top-left-radius: 22.2083px;
        border-bottom-left-radius: 22.2083px;
        .info {
          margin-left: 66.625px;
          width: 183.219px;
          .title {
            margin-bottom: 78.2844px;
            .icon {
              width: 26.6406px;
              height: 28.8594px;
              margin-right: 3.88646px;
            }
            .text {
              font-size: 19.9875px;
            }
          }
          .desc {
            .desc-item {
              margin-bottom: 26.65px;
              .title {
                margin-bottom: 4.44167px;
                line-height: 18.3219px;
                font-size: 13.325px;
              }
              .text {
                line-height: 16.6562px;
                font-size: 10px;
              }
            }
          }
        }
        .main {
          top: 45.5271px;
          left: 315.914px;
          img {
            margin-right: 10.549px;
            border-radius: 8.88333px;
            &:nth-child(1) {
              width: 149.906px;
            }
            &:nth-child(2) {
              width: 189.312px;
            }
            &:nth-child(3) {
              width: 188.766px;
            }
          }
        }
      }
      .live-operation {
        width: 932.75px;
        height: 377.531px;
        padding-top: 44.4167px;
        padding-bottom: 68.2906px;
        margin-bottom: 88.8333px;
        border-top-right-radius: 22.2083px;
        border-bottom-right-radius: 22.2083px;
        .info {
          margin-left: 566.312px;
          .title {
            margin-bottom: 64.4042px;
            .icon {
              width: 27.75px;
              height: 26.6406px;
              margin-right: 10.549px;
            }
            .text {
              line-height: 22.2083px;
              font-size: 19.9875px;
            }
          }
          .desc {
            .desc-item {
              margin-bottom: 26.65px;
              .title {
                margin-bottom: 4.44167px;
                line-height: 18.3219px;
                font-size: 13.325px;
              }
              .text {
                line-height: 16.6562px;
                font-size: 10px;
              }
            }
          }
        }
        .main {
          top: 61.0729px;
          left: 133.25px;
          img {
            margin-right: 8.88333px;
            border-radius: 8.88333px;
            &:nth-child(1) {
              width: 194.875px;
            }
            &:nth-child(2) {
              width: 172.109px;
            }
          }
        }
      }
    }
  }
}
</style>
