<template>
  <div class="anchor-container">
    <shrinkable-header ref="header" class="anchor-header">
      <p-header :fixed="false" type="flow"></p-header>
      <sub-header title="主播">
        <template v-slot:title>
          <div class="box">
            <img
              src="https://mktv-in-cdn.mockuai.com/16274407896936447.png"
              class="icon"
            />
            <span class="text">主播合作</span>
          </div>
        </template>
        <ul class="nav">
          <li
            class="nav-item"
            data-menuanchor="service"
            @click="changeMoveMode"
          >
            <a href="#service">服务概述</a>
          </li>
          <li class="nav-item" data-menuanchor="cases" @click="changeMoveMode">
            <a href="#cases">成功案例</a>
          </li>
          <li class="nav-item" data-menuanchor="study" @click="changeMoveMode">
            <a href="#study">学习中心</a>
          </li>
        </ul>
      </sub-header>
    </shrinkable-header>
    <tools-bar ref="toolsBar" @backtop="onBacktop"></tools-bar>
    <div id="anchor-fullpage">
      <div ref="firstSection" class="section fp-auto-height-responsive">
        <service-section></service-section>
      </div>
      <div class="section fp-auto-height-responsive">
        <cases-section></cases-section>
      </div>
      <div class="section fp-auto-height-responsive">
        <study-section></study-section>
      </div>
    </div>
  </div>
</template>

<script>
import { decode } from 'js-base64'
import Header from '@/layout/Header.vue'
import SubHeader from '@/components/SubHeader.vue'
import ShrinkableHeader from '@/components/ShrinkableHeader.vue'
import ServiceSection from '@/components/anchor/ServiceSection.vue'
import CasesSection from '@/components/anchor/CasesSection.vue'
import StudySection from '@/components/anchor/StudySection.vue'
import ToolsBar from '@/components/ToolsBar.vue'

let isFirstSection = false
let anchorMove = false

export default {
  head: {
    title: '主播合作-直播带货主播-主播签约机构-主播带货合作-魔筷科技',
    meta: [
      {
        name: 'Keywords',
        content:
          '主播服务运营,带货主播,直播主播,直播培训课程,短视频培训',
      },
      {
        name: 'Description',
        content:
          '【魔筷科技-直播电商服务机构】提供专业的主播专场策划运营服务,从选品选货、直播运营到内容策划、成长培训等,还包括直播短视频课程培训,拥有众多的直播电商专业讲师。',
      },
    ],
  },
  components: {
    'p-header': Header,
    SubHeader,
    ShrinkableHeader,
    ServiceSection,
    CasesSection,
    StudySection,
    ToolsBar,
  },
  mounted() {
    this.initFullpage()
    this.$refs.toolsBar.show()
  },
  destroyed() {
    window.fullpage_api.destroy()
  },
  methods: {
    initFullpage() {
      require('fullpage.js/vendors/scrolloverflow.min.js')
      const Fullpage = require('fullpage.js')
      const self = this
      new Fullpage('#anchor-fullpage', {
        licenseKey: decode(window.__INITIAL_STATE__.fullpageKey),
        anchors: ['service', 'cases', 'study'],
        menu: '.sub-header .nav',
        verticalCentered: false,
        scrollOverflow: true,
        scrollOverflowOptions: {
          probeType: 3,
        },
        touchSensitivity: 80,
        fixedElements: '.anchor-header, .qrcode-modal, .video-modal',
        // paddingTop: '150px',
        afterRender() {
          window.fullpage_api.reBuild()
          window.addEventListener('wheel', () => {
            anchorMove = false
            // console.log('wheel', e)
            if (isFirstSection) {
              let ele = self.$refs.firstSection.querySelector(
                '.fp-scrollable .fp-scroller'
              )
              // let ele = document.querySelector('.fp-section.active .fp-scrollable .fp-scroller')
              const a = window
                .getComputedStyle(ele, null)
                .getPropertyValue('transform')
              // console.log(a)
              let y = Math.abs(
                a.split(',')[a.split(',').length - 1].split(')')[0]
              )
              console.log(y)
              if (y <= 90) {
                // self.$refs.header.style.top = `-${y}px`
                self.$refs.header.changeStyle('top', `-${y}px`)
                self.$refs.firstSection.style.paddingTop = `${150 - y}px`
              } else {
                // self.$refs.header.style.top = '-90px'
                self.$refs.header.changeStyle('top', '-90px')
                self.$refs.firstSection.style.paddingTop = '60px'
              }
              window.fullpage_api.reBuild()
            }
          })
        },
        afterLoad(origin, dest, dir) {
          console.log('afterload', origin, dest, dir)
          let iscroll =
            document.querySelector('.fp-section.active .fp-scrollable') &&
            document.querySelector('.fp-section.active .fp-scrollable')
              .fp_iscrollInstance
          console.log(iscroll)

          if (anchorMove && iscroll) {
            iscroll.scrollTo(0, 0, 600)
          }

          isFirstSection = dest.isFirst
          if (dest.isFirst) {
            // self.$refs.header.style.top = '0'
            self.$refs.header.changeStyle('top', 0)
          } else {
            // self.$refs.header.style.top = '-90px'
            self.$refs.header.changeStyle('top', '-90px')
          }
        },
        afterReBuild: () => {
          console.log('fullpage rebuild!')
        },
        onLeave(origin, dest, dir) {
          console.log(origin, dest, dir)
          // if (!dest.isFirst) {
          //   self.$refs.header.style.top = '-90px'
          // } else {
          //   if (dir === 'up') {
          //     self.$refs.header.style.top = '-90px'
          //   }
          // }
        },
      })
    },
    onBacktop() {
      window.fullpage_api.moveTo(1)
      let iscroll =
        document.querySelector('.fp-section.active .fp-scrollable') &&
        document.querySelector('.fp-section.active .fp-scrollable')
          .fp_iscrollInstance
      if (iscroll) {
        iscroll.scrollTo(0, 0, 600)
      }
    },
    changeMoveMode() {
      anchorMove = true
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  display: flex;
  align-items: center;
  .icon {
    width: 28px;
    height: 28px;
    margin-right: 9px;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff6600;
  }
}
.anchor-container {
  .section {
    transition: all 0.5s;
    &:nth-child(1) {
      padding-top: 150px;
    }
    &:nth-child(2) {
      padding-top: 60px;
      background-color: #fff;
    }
    &:nth-child(3) {
      padding-top: 60px;
      background-color: #fff;
    }
  }
}
</style>
